export const productScrollTargets = [
  'product-review-section',
  'product-intro-section',
  'product-curriculum-section',
  'product-faq-section',
  'product-comment-section',
] as const;
export type ProductScrollTargets = typeof productScrollTargets;

export const PRODUCT_SEND_REDIRECT_TARGET = 'redirect_seq';
// 3718: 내마기
// 3618: 열기
export const PRODUCT_DETAIL_TARGETS = [3618, 3718, 3955];
